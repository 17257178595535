<template>
  <div id="kt_footer" class="footer bg-white py-4 d-flex flex-lg-column">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2021©</span>
        <a target="_blank" class="text-dark-75 text-hover-primary"
          >科易博软件</a
        >
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备16000534号</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <a target="_blank" class="nav-link pr-3 pl-0" v-on:click="showShareMsg"
          >分享</a
        >
        <!--<a target="_blank" class="nav-link px-3">团队</a>-->
        <a target="_blank" class="nav-link pl-3 pr-0" v-on:click="showComMsg"
          >关于</a
        >
      </div>
      <!--end::Nav-->
    </div>
    <div
      class="modal fade"
      id="comMsg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="comMsg"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            科易博软件(KYBSOFT)位于广东深圳，是一家专注于PCB行业ERP和云平台服务的软件公司<br />
            科易博软件的主要产品有PCBERP和PCB智能云。其中PCBERP是充分利用移动互联网新技术，为PCB企业量身打造的一款新型ERP软件，通过技术手段的改变，对业务流程、信息周转有进一步的提升，致力于为用户提供够用、好用、易用的行业专用ERP系统
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="shareMsg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="shareMsg"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <img :src="kybCloud" class="w-75" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),
    kybCloud() {
      return process.env.BASE_URL + "media/test/kybCloud.png";
    },

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  },
  methods: {
    showComMsg: function() {
      $("#comMsg").modal();
    },
    showShareMsg: function() {
      $("#shareMsg").modal();
    }
  }
};
</script>
