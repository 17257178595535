<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a v-on:click="toMain">
      <img alt="Logo" :src="headerLogo" class="logo-default max-h-30px" />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center text-white">
      <!--<button-->
      <!--class="btn btn-icon btn-hover-transparent-white p-0 ml-3"-->
      <!--id="kt_quick_user_mobile_toggle"-->
      <!--&gt;-->
      <!--<span class="svg-icon svg-icon-xl">-->
      <!--&lt;!&ndash;begin::Svg Icon | path:svg/icons/General/User.svg&ndash;&gt;-->
      <!--<inline-svg class="svg-icon" src="media/svg/icons/General/User.svg" />-->
      <!--&lt;!&ndash;end::Svg Icon&ndash;&gt;-->
      <!--</span>-->
      <!--</button>-->
      <KTQuickUser></KTQuickUser>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";

export default {
  name: "KTHeaderMobile",
  components: {
    KTQuickUser
  },
  data() {
    return {
      user: {}
    };
  },
  mounted() {
    // Init Header Topbar For Mobile Mode
    this.initData();
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    initData() {},
    toMain: function() {
      this.$router.push("/dashboard");
    }
  }
};
</script>
